
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '@angular/material/theming' as theme;
// Plus imports for other components in your app.
@use './assets/variables' as variables;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// プライマリーカラーパレット(ボタンの色を管理)
$primary-palette: (
  default: variables.$primary-default,  // 使用中:ボタンの色
  lighter: #ff923e,  // 未使用
  darker:  #c42b00,  // 未使用

  // 文字色(背景の明暗に応じて白文字か黒文字かを指定)
  contrast: (
    default: theme.$light-primary-text, // 白文字
    lighter: theme.$light-primary-text, // 白文字
    darker: theme.$light-primary-text   // 白文字
  )
);

$contrast: mat.get-contrast-color-from-palette($primary-palette, lighter);

// アクセントカラーパレット(メイン色を管理(緑系))
$accent-palette: (
  default: variables.$accent-default, // 使用中:サイドナビなど
  lighter: variables.$accent-lighter, // 使用中:カードタイトルの背景色など
  darker: variables.$accent-darker, // 使用中:ヘッダー、カードタイトルの文字色など

  // 文字色(背景の明暗に応じて白文字か黒文字かを指定)
  contrast: (
    default: theme.$light-primary-text, // 白文字
    lighter: theme.$dark-primary-text,  // 黒文字
    darker: theme.$light-primary-text   // 白文字
  )
);
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Contract-primary: mat.define-palette($primary-palette, default, lighter, darker);
$Contract-accent: mat.define-palette($accent-palette, default, lighter, darker);

// The warn palette is optional (defaults to red).
$Contract-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$Contract-theme: mat.define-light-theme((
  color: (
    primary: $Contract-primary,
    accent: $Contract-accent,
    warn: $Contract-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Contract-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { 
  margin: 0; 
  font-family: Roboto,"Helvetica Neue", sans-serif; 
  background-color: #397E69;
}
